import { fechFromBPartsApi } from '../utils';

export const login = async (
  variables: TSaveResquest
): Promise<TLoggedUser | undefined> => {
  const response = await fechFromBPartsApi(
    `/${variables.locale}/shop/accounts/login`,
    {
      method: 'post',
      headers: new Headers([
        ['Content-Type', 'application/json'],
        ['X-CSRFToken', variables.csrftoken],
      ]),
      credentials: 'include',
      body: JSON.stringify(variables.formData),
    }
  );

  let json;
  try {
    json = await response.json();
  } catch (error) {
    throw { serverError: error };
  }

  if (response.status === 200) {
    return json;
  }

  if (!response.ok) {
    throw json;
  }
  return;
};
