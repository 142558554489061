import cx from 'classnames';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

import useLinkProps from '../../../hooks/use-link-props';
import HelpContacts from '../../modules/help-contacts';
import BPartsBicon from '../../ui/icons/bparts-b-icon';
import FeaturedLink from '../../ui/links/featured';

const HelpInfo = () => {
  const { t } = useTranslation();
  const getLink = useLinkProps();

  const termsLink = (
    <Link {...getLink('terms-and-conditions', undefined)} passHref legacyBehavior>
      <FeaturedLink
        variant="neutral"
        className="font-bold text-blue-100"
        target="_blank"
      >
        {t('login:terms-link')}
      </FeaturedLink>
    </Link>
  );

  const privacyLink = (
    <Link
      {...getLink('terms-and-conditions', undefined, t('login:privacy-hash'))}
      passHref
      legacyBehavior>
      <FeaturedLink
        variant="neutral"
        className="font-bold text-blue-100"
        target="_blank"
      >
        {t('login:privacy-link')}
      </FeaturedLink>
    </Link>
  );
  const cookiesLink = (
    <Link
      {...getLink('terms-and-conditions', undefined, t('login:cookies-hash'))}
      passHref
      legacyBehavior>
      <FeaturedLink
        variant="neutral"
        className="font-bold text-blue-100"
        target="_blank"
      >
        {t('login:cookies-link')}
      </FeaturedLink>
    </Link>
  );
  return <>
    <div
      className={cx(
        'separator-bottom',
        'lg:separator-left',
        'py-6',
        'px-4',
        'flex',
        'flex-col',
        'gap-6'
      )}
    >
      <aside className="lg:order-2">
        <div className={cx('flex', 'items-center', 'mb-2')}>
          <BPartsBicon className={cx('h-12', 'w-12', 'mr-4')} />
          <div>
            <span className={cx('font-bold', 'tracking-wide')}>
              {t('login:signup')}
            </span>
          </div>
        </div>
        <h2 className={cx('font-bold', 'tracking-wide')}>
          {t('login:have-account')}
        </h2>
        <Link {...getLink('signup')} passHref legacyBehavior>
          <FeaturedLink>{t('login:create-account')}</FeaturedLink>
        </Link>
      </aside>
      <HelpContacts />
    </div>
    <p className="lg:col-span-2 px-4 py-6 text-sm">
      <Trans
        i18nKey="login:agree-terms"
        components={{
          b: <b />,
          termsLink: termsLink,
          privacyLink: privacyLink,
          cookiesLink: cookiesLink,
        }}
      />
    </p>
  </>;
};

export default HelpInfo;
