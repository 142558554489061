import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent } from 'react';

import useLinkProps from '../../../hooks/use-link-props';
import { ADVANCED_VIEW_ACTIVE } from '../../../static/cookies';
import getLoggedUser from '../../../utils/react-query/query-options/get-logged-user';
import Breadcrumbs from '../../modules/breadcrumbs';
import PageTitle from '../../ui/typography/page-title';

import LoginUpForm from './form';
import HelpInfo from './help-info';
import LoginSchema from './seo';

const LoginPage: FunctionComponent<TPageLogin> = () => {
  const { t } = useTranslation();
  const getLink = useLinkProps();
  const { locale = 'en', push, query, asPath } = useRouter();
  const { data: user, isLoading } = useQuery(...getLoggedUser({ locale }), {
    refetchOnMount: true,
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data) {
        if (query.redirect) {
          push(decodeURIComponent(query.redirect));
          return;
        } else if (data?.staff?.is_pro) {
          if (parseInt(Cookies.get(ADVANCED_VIEW_ACTIVE) || '0')) {
            const link = getLink('advanced-search');
            push(link.href, link.as);
          } else {
            const link = getLink('private-area');
            push(link.href, link.as);
          }
        } else {
          const link = getLink('private-area');
          push(link.href, link.as);
        }
      }
    },
  });

  return (
    <main>
      <LoginSchema breadcrumbs={[]} />
      <Breadcrumbs items={[]} currentPage={t('login:login')} />
      <div
        className={cx(
          'container',
          'mx-auto',
          'lg:my-12',
          'my-4',
          'grid',
          'lg:grid-cols-3',
          'pb-20'
        )}
      >
        <div className={cx('lg:col-span-2')}>
          <div className="pl-4">
            <PageTitle title={t('login:login')} subtitle={t('login:detail')} />
          </div>
          {!(isLoading || user) && <LoginUpForm />}
        </div>
        <div>
          <HelpInfo />
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
