import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';

import usePageViewEvent from '../../../hooks/use-page-view-event';
import useHreflang from '../../../utils/seo/hreflang';
import useOGMeta from '../../../utils/seo/ogmeta';
import useSchema from '../../../utils/seo/schema-org/schema';
import { useAppContext } from '../../providers/app-context';

const LoginSchema: FunctionComponent<{
  breadcrumbs: Array<{
    href: LinkProps;
    text: string;
  }>;
}> = ({ breadcrumbs }) => {
  const { t } = useTranslation();
  const name = t('login:head.title');
  const { businessValues } = useAppContext();

  const description = t('login:head.description', {
    totalStockAvailable: businessValues.totalStockAvailable.defaultValue,
  });

  const { locale = 'en', asPath } = useRouter();
  const localePath = locale !== 'en' ? `/${locale}` : '';

  const og = useOGMeta({
    title: name,
    description,
    url: process.env.NEXT_PUBLIC_HOST + localePath + asPath,
  });

  const alternateLinks = useHreflang({
    locale,
    route: 'login',
  });

  usePageViewEvent(name);

  return (
    <Head>
      <title>{name}</title>
      <meta name="description" content={description} />
      {alternateLinks}
      {og}

      <script {...useSchema({ name, description, breadcrumbs })} />
    </Head>
  );
};

export default LoginSchema;
