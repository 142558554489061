import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Graph, ListItem, Thing, WebPage } from 'schema-dts';
import { useBreadcrumbsType } from '../../../components/pages/vehicle/hooks/use-breadcrumbs';

const useSchema = ({
  name,
  description,
  breadcrumbs,
  extraSchema = [],
}: {
  name: string;
  description: string;
  breadcrumbs: ReturnType<useBreadcrumbsType>;
  extraSchema: [];
}) => {
  const { locale = 'en', asPath } = useRouter();
  const localePath = locale !== 'en' ? `/${locale}` : '';

  return jsonLdScriptProps({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebPage',
        '@id': `${process.env.NEXT_PUBLIC_HOST}${localePath}${asPath}#webpage`,
        url: `${process.env.NEXT_PUBLIC_HOST}${localePath}${asPath}`,
        inLanguage: locale,
        name,
        description,
        breadcrumb: {
          '@id': `${process.env.NEXT_PUBLIC_HOST}${localePath}${asPath}#breadcrumb`,
        },
      },
      {
        '@type': 'BreadcrumbList',
        '@id': `${process.env.NEXT_PUBLIC_HOST}${localePath}${asPath}#breadcrumb`,
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@type': 'WebPage',
              '@id': `${process.env.NEXT_PUBLIC_HOST}${localePath}`,
              url: `${process.env.NEXT_PUBLIC_HOST}${localePath}`,
              name: 'Home',
            },
          },
          ...breadcrumbs
            .filter(({ text, as, href }) => !!text && !!(as || href.as))
            .map<ListItem>((item, index) => ({
              '@type': 'ListItem',
              position: index + 2,
              item: {
                '@type': 'WebPage',
                '@id': `${process.env.NEXT_PUBLIC_HOST}${
                  item.as || item.href?.as
                }`,
                url: `${process.env.NEXT_PUBLIC_HOST}${
                  item.as || item.href?.as
                }`,
                name: item.text,
              },
            })),
        ],
      },
      ...extraSchema,
    ],
  });
};

export default useSchema;
