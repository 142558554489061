import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React, { FunctionComponent, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

import useLinkProps from '../../../hooks/use-link-props';
import Button from '../../ui/button';
import { CheckBox, Text } from '../../ui/inputs/generic-input';
import FeaturedLink from '../../ui/links/featured';

const SignUpFormContent: FunctionComponent<{
  register: UseFormRegister<Record<string, any>>;
  errors: Record<string, any>;
  disabled: boolean;
  isValid: boolean;
}> = ({ register, errors, disabled, isValid }) => {
  const { t } = useTranslation();
  const getLink = useLinkProps();
  const [inputType, setInputType] = useState('password');
  return <>
    <div>
      <Text
        {...register('username', {
          required: t('forms:errors.required'),
          maxLength: {
            value: 150,
            message: t('forms:errors.maxLenght', { value: 150 }),
          },
          pattern: {
            value: /^\S+@\S+$/i,
            message: t('forms:errors.invalidEmail'),
          },
        })}
        label={t('forms:user.email.label')}
        type="email"
        id={`email`}
        placeholder={t('forms:user.email.placeholder')}
        disabled={disabled}
        error={errors?.username?.message}
      />
    </div>
    <div>
      <Text
        {...register('password', {
          required: t('forms:errors.required'),
          minLength: {
            value: 6,
            message: t('forms:errors.minLenght', { value: 6 }),
          },
        })}
        label={t('forms:user.password.label')}
        type={inputType}
        id="password"
        placeholder={t('forms:user.password.placeholder')}
        error={errors?.password?.message}
        disabled={disabled}
      />
    </div>
    <div>
      <CheckBox
        label={t('forms:password.show_password.label')}
        id="showPassword"
        disabled={disabled}
        onChange={() =>
          setInputType(inputType === 'password' ? 'text' : 'password')
        }
      />
    </div>

    <span className="p-4 pt-0 lg:px-0">
      <Link {...getLink('password-reset')} passHref legacyBehavior>
        <FeaturedLink>{t('login:password-reset')}</FeaturedLink>
      </Link>
    </span>
    <div className="flex w-full">
      <Button
        role="button"
        variant="CTA"
        data-action="submit"
        disabled={!isValid}
      >
        {t('login:login-btn')}
      </Button>
    </div>
  </>;
};
export default SignUpFormContent;
