import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useCallback } from 'react';
import React from 'react';
import { useForm } from 'react-hook-form';

import useLinkProps from '../../../hooks/use-link-props';
import { login } from '../../../services/auth/login';
import { ADVANCED_VIEW_ACTIVE } from '../../../static/cookies';
import { useAddAlert, useRemoveAlert } from '../../modules/alerts';

import LoginFormContent from './form-content';

const LoginForm = () => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const { locale = 'en', push, query: queryParams } = useRouter();
  const getLink = useLinkProps();
  const addAlert = useAddAlert();
  const removeAlert = useRemoveAlert();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const { mutate, isLoading } = useMutation(login, {
    onSuccess(data) {
      window.dataLayer.push({
        event: 'userLogin',
        userID: data?.staff.id, // user id
      });
      removeAlert('login');
      let link;
      client.removeQueries('user-dependent');

      if (data?.staff?.is_pro) {
        if (typeof Cookies.get(ADVANCED_VIEW_ACTIVE) === 'undefined') {
          Cookies.set(ADVANCED_VIEW_ACTIVE, '1', { expires: 365 });
        }
      }

      if (queryParams.redirect) {
        window.location = decodeURIComponent(queryParams.redirect);
        return;
      } else if (queryParams.next) {
        window.location = decodeURIComponent(queryParams.next);
        return;
      } else if (data.redirect_to) {
        window.location = decodeURIComponent(data.redirect_to);
        return;
      } else if (queryParams.next) {
        link = getLink(queryParams.next, undefined);
      } else if (!link?.as) {
        link = getLink('private-area', undefined);
      }
      window.location = link.as;
      //push(link.href, link.as);
    },
    onError(error: Record<string, any>) {
      addAlert({
        id: 'login',
        text: error.serverError
          ? t('forms:errors.serverError')
          : t('login:error'),
        items: error.non_field_errors,
        type: 'DANGER',
      });
      Object.keys(error).map(function (key, i) {
        if (key !== 'non_field_errors' && key !== 'serverError') {
          setError(key, {
            type: 'manual',
            message: error[key],
          });
        }
      });
    },
  });

  const onSubmit = useCallback(
    (formData) => {
      mutate({
        locale: locale,
        formData,
        csrftoken: Cookies.get('csrftoken') || '',
      });
    },
    [mutate, locale]
  );

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:px-4 pt-6 flex flex-col gap-4 w-full lg:w-2/3"
      >
        <LoginFormContent
          register={register}
          errors={errors}
          disabled={isLoading}
          isValid={isValid && Object.keys(errors).length == 0 && !isLoading}
        />
      </form>
    </>
  );
};
export default LoginForm;
